import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Select,
  Divider,
  Descriptions
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { saveClientData } from "../../redux/actions/clientActions";

const EditClientDrawer = ({
  isEditableDrawerOpen,
  setIsEditableDrawerOpen,
  particularClientInformation,
  setParticularClientInformation,
  reloadClients,
}) => {
  const [form] = Form.useForm();
  const isAdd = JSON.stringify(particularClientInformation) === '{}';
  const dispatch = useDispatch();
  const { filterData = {} } = useSelector(({ survey }) => survey);

  const onClose = () => {
    setIsEditableDrawerOpen(false);
  };

  const onSaveSuccess = () => {
    setIsEditableDrawerOpen(false);
    reloadClients();
  }

  const onFinish = (value) => {
    console.log(value);
    value.other = {}
    dispatch(saveClientData(value, onSaveSuccess));
  };

  useEffect(() => {
    if (Object.keys(particularClientInformation).length) {
      form.setFieldsValue(particularClientInformation);
    }
  }, [particularClientInformation, form]);

  return (
    <>
      <Drawer
        title="Client Details"
        width={720}
        closable={false}
        onClose={onClose}
        open={isEditableDrawerOpen}
        placement="right"
        style={{ position: "fixed", right: 0 }}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button form="client_submit_form" key="submit" htmlType="submit" type="primary">
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          id="client_submit_form"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          initialValues={{
            name: particularClientInformation.name,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="id" label="Unique Identification Number">
                <Input disabled={true} size="large"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item 
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  }
                ]}
              
              >
                <Input bordered={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="website"
                label="Website"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  placeholder="Please enter url"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Enter email address",
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                name="company_name"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter company name",
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="account_email"
                label="Account Email"
                rules={[
                  {
                    required: true,
                    message: "Enter account email address",
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Enter Phone Number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="hsn_sac"
                label="hsn sac"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="gst" label="GST">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Select Country",
                    type: 'string',
                  },
                ]}
              >
                <Select
                  listItemHeight={10}
                  listHeight={250}
                  showSearch
                  size="large"
                  placeholder="Please Select Country"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={filterData.basic.country.map(option => { return { value: option.code, label: option.name } })}
                >
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="region" label="Region">
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="img"
                label="Image Url"
                rules={[
                  {
                    required: true,
                    message: "Please enter link here",
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {!isAdd && 
          <>
            <Divider>Links Information</Divider>
            <Descriptions bordered>
              <Descriptions.Item label="Complete URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/cid/"+ particularClientInformation.id +"/capture/success"}</Descriptions.Item>
              <Descriptions.Item label="Security URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/cid/"+ particularClientInformation.id +"/capture/qualityfail"}</Descriptions.Item>
              <Descriptions.Item label="Quota Full URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/cid/"+ particularClientInformation.id +"/capture/overquota"}</Descriptions.Item>
              <Descriptions.Item label="Terminate URL (for client)" span={3}>{process.env.REACT_APP_BASE_API_URL + "/v1/api/link/cid/"+ particularClientInformation.id +"/capture/terminate"}</Descriptions.Item>
            </Descriptions>
          </>
          }
      </Drawer>
    </>
  );
};
export default EditClientDrawer;
