import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Typography,
  Button,
  Input,
  Space,
  Popover,
  Tag,
  Select,
} from "antd";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getFilterData,
  getSurveyList,
} from "../redux/actions/surveyActions/index";
// import { Anchor } from "antd";
import EditSurveyDrawer from "../components/EditSurveyDrawer/index.js";
import CampaignDrawer from "../components/FilterForCampaingDrawer/index";
import InvoiceClientDrawer from "../components/InvoiceClientDrawer";
import { saveSurveyData } from "../redux/actions/surveyActions";

import {
  EditTwoTone,
  FilterTwoTone,
  PlusOutlined,
  UpSquareTwoTone,
  DownSquareTwoTone,
} from "@ant-design/icons";
import InvoiceVendorDrawer from "../components/InvoiceVendorDrawer";

// import Highlighter from 'react-highlight-words';

const { Title, Paragraph } = Typography;
const { Search } = Input;

function SurveyCampaign() {
  // Control drawer
  const [isEditableDrawerOpen, setIsEditableDrawerOpen] = useState(false);
  const [isInvoiceClientDrawerOpen, setIsInvoiceClientDrawerOpen] =
    useState(false);
  const [isInvoiceVendorDrawerOpen, setIsInvoiceVendorDrawerOpen] =
    useState(false);
  const [isCampaignDrawerOpen, setIsCampaignDrawerOpen] = useState(false);
  const [particularSurveyInformation, setParticularSurveyInformation] =
    useState({});
  const [particularCampaignInformation, setParticularCampaignInformation] =
    useState({});

  const { surveys = {}, fetching } = useSelector(({ survey }) => survey);
  const { items = [], pagination = {} } = surveys;
  const [isLoading, setIsLoading] = useState(false);
  const [filterKeyName, setFilterKeyName] = useState("ci_code__icontains");

  const { total = 200, page = 1, page_size = 100 } = pagination;

  // Fetch data
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSurveyList({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFilterData({}));
  }, [dispatch]);

  const onSaveSuccess = () => {
    setIsLoading(false);
    reloadSurveys();
  };

  const SaveSurveyRecord = (record) => {
    console.log(record);
    setIsLoading(true);
    record.created_at = moment(record.created_at, "DD MMM YY, h:mm:ss").format(
      "YYYY-MM-DD"
    );
    record.start_date = moment(record.start_date, "DD MMM YY, h:mm:ss").format(
      "YYYY-MM-DD"
    );
    record.end_date = moment(record.end_date, "DD MMM YY, h:mm:ss").format(
      "YYYY-MM-DD"
    );
    const metadata = record.metadata;
    record.is_agent_check_enabled =
      metadata != undefined &&
      "is_agent_check_enabled" in metadata &&
      metadata.is_agent_check_enabled;
    record.is_internal_panel =
      metadata != undefined &&
      "is_agent_check_enabled" in metadata &&
      metadata.is_internal_panel;
    dispatch(saveSurveyData(record, onSaveSuccess));
  };

  let tableParams = {
    pagination: { current: page, pageSize: page_size, total: total },
  };

  const reloadSurveys = () => {
    dispatch(getSurveyList({}));
  };

  const onEditClick = (surveyInformation) => {
    setIsEditableDrawerOpen(true);
    setParticularSurveyInformation(surveyInformation);
  };

  const onAddClick = (surveyInformation) => {
    setIsEditableDrawerOpen(true);
    setParticularSurveyInformation({});
  };

  const onFilterClick = (campaignInformation) => {
    setIsCampaignDrawerOpen(true);
    setParticularCampaignInformation(campaignInformation);
  };

  const onInvoiceClientClick = () => {
    setIsInvoiceClientDrawerOpen(true);
  };

  const onInvoiceVendorClick = () => {
    setIsInvoiceVendorDrawerOpen(true);
  };

  const columns = [
    {
      title: (
        <>
          <Title level={5}>Campaign Id : CI code : Name : Country</Title>
        </>
      ),
      dataIndex: "name",
      key: "name",
      bordered: true,

      render: (data, record) => (
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="round"
            size={30}
            src={record.ui_icon}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              <Space>
                {record.id} : {record.ci_code}:{record.name}:{" "}
                <Tag color="lime">{record.country}</Tag>
                <Popover content="Edit Details">
                  <EditTwoTone onClick={() => onEditClick(record)} />
                </Popover>
              </Space>
            </Title>
            <Paragraph copyable>
              {process.env.REACT_APP_BASE_API_URL +
                "/v1/api/link/start?camp=" +
                record.id +
                "&resp=[XXX]"}
            </Paragraph>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: (
        <>
          <Title level={5}>Client Code : Wave</Title>
          <Paragraph>Client CPI</Paragraph>
        </>
      ),
      dataIndex: "ci_code",
      key: "ci_code",
      render: (data, record) => (
        <>
          <Title level={5}>
            {record.client_code} : {record.wave}{" "}
          </Title>
          <p>{record.client_cpi}</p>
        </>
      ),
    },
    {
      title: <Title level={5}>Status</Title>,
      dataIndex: "status",
      key: "status",
      render: (data, record) => (
        <Space>
          <Select
            size="small"
            bordered={false}
            style={{ width: "100%", height: "110%" }}
            defaultValue={record.status}
            onChange={(value) => {
              console.log(value, record);
              record.status = value;
              SaveSurveyRecord(record);
            }}
            options={[
              {
                value: "NEW",
                label: <Tag color="lime">NEW</Tag>,
              },
              {
                value: "LIVE",
                label: <Tag color="green">LIVE</Tag>,
              },
              {
                value: "PAUSE",
                label: <Tag color="gold">PAUSE</Tag>,
              },
              {
                value: "CLOSED",
                label: <Tag color="red">CLOSED</Tag>,
              },
              {
                value: "INVOICE",
                label: <Tag color="blue">INVOICE</Tag>,
              },
              {
                value: "PAID",
                label: <Tag color="purple">PAID</Tag>,
              },
            ]}
          />
          {["CLOSED", "INVOICE", "PAID"].includes(record.status) && (
            <>
              <UpSquareTwoTone onClick={onInvoiceClientClick} />
              <DownSquareTwoTone onClick={onInvoiceVendorClick} />
            </>
          )}
        </Space>
      ),
    },

    {
      title: (
        <>
          <Title level={5}>Start Date : End Date</Title>{" "}
          <Paragraph>Last Updated at</Paragraph>
        </>
      ),
      dataIndex: "created_at",
      key: "created_at",
      render: (data, record) => (
        <>
          <Paragraph>
            <Tag color="green">{record.start_date.slice(0, 9)}</Tag>:{" "}
            <Tag color="orange">{record.end_date.slice(0, 9)}</Tag>
          </Paragraph>
          <Tag>{record.updated_at}</Tag>
        </>
      ),
    },
  ];

  const onSearch = (value) => {
    let filters = {};
    filters[filterKeyName] = value;
    const filterdata = {
      filters: filters,
      page: tableParams.pagination.current,
    };
    dispatch(getSurveyList(filterdata));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    tableParams.pagination.current = pagination.current;
    onSearch();
  };

  const handleSearchInputChange = (value) => {
    setFilterKeyName(value);
  };

  return (
    <>
      <div className="tabled site-card-border-less-wrapper">
        <Card
          bordered={false}
          className="criclebox tablespace mb-24"
          title="Surveys Details"
          extra={
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ color: "white", zIndex: 10 }}
              onClick={onAddClick}
            >
              Add Survey
            </Button>
          }
        >
          <Row>
            <Col span={8}>
              <Input.Group compact style={{ marginLeft: 20 }}>
                <Select
                  key="filterKeyName"
                  size="large"
                  style={{ width: "40%" }}
                  defaultValue="ci_code__icontains"
                  onChange={handleSearchInputChange}
                  options={[
                    {
                      value: "ci_code__icontains",
                      label: "CI Code",
                    },
                    {
                      value: "client_code__icontains",
                      label: "Client Code",
                    },
                    {
                      value: "name__icontains",
                      label: "Name",
                    },
                    {
                      value: "id",
                      label: "Campaign Id",
                    },
                  ]}
                />
                <Search
                  placeholder="Search Value"
                  onSearch={onSearch}
                  enterButton
                  style={{ width: "60%" }}
                />
              </Input.Group>
            </Col>
          </Row>
          <div className="table-responsive">
            <Table
              columns={columns}
              dataSource={items}
              pagination={tableParams.pagination}
              loading={fetching}
              rowKey={(record) => record.id}
              onChange={handleTableChange}
              className="ant-border-space"
            />
          </div>
        </Card>
      </div>
      {isEditableDrawerOpen && (
        <EditSurveyDrawer
          setIsEditableDrawerOpen={setIsEditableDrawerOpen}
          particularSurveyInformation={particularSurveyInformation}
          reloadSurveys={reloadSurveys}
        />
      )}
      {isCampaignDrawerOpen && (
        <CampaignDrawer
          isCampaignDrawerOpen={isCampaignDrawerOpen}
          setIsCampaignDrawerOpen={setIsCampaignDrawerOpen}
          particularCampaignInformation={particularCampaignInformation}
        />
      )}
      {isInvoiceClientDrawerOpen && (
        <InvoiceClientDrawer
          isInvoiceClientDrawerOpen={isInvoiceClientDrawerOpen}
          setIsInvoiceClientDrawerOpen={setIsInvoiceClientDrawerOpen}
        />
      )}
      {isInvoiceVendorDrawerOpen && (
        <InvoiceVendorDrawer
          isInvoiceVendorDrawerOpen={isInvoiceVendorDrawerOpen}
          setIsInvoiceVendorDrawerOpen={setIsInvoiceVendorDrawerOpen}
        />
      )}
    </>
  );
}
export default SurveyCampaign;
